<template>
  <div>
    <el-page-header class="back-header" :class="{'back-header-admin': detailType === 'admin'}"
                    :title="$t('back')"
                    @back="handleBack" :content="$t('artDetail.title')"></el-page-header>
    <div class="content-box" v-if="!showError"
         v-loading="loading" element-loading-text="数据加载中">
      <div class="version-box">

      </div>
      <div class="action-box" v-if="detailType === 'user'">
        <el-button v-if="artDetail.status === 1" @click="handleBtnAudit">提交审核</el-button>
        <el-button v-if="artDetail.status === 1" @click="handleBtnEdit">修改</el-button>
      </div>
      <div class="up-box">
        <div class="left">
          <div class="author-box" v-if="detailType !== 'user'">
            <div class="name">{{ $t('artDetail.uploader') }}：{{artDetail.user.name}}</div>
          </div>
          <div class="title">{{artDetail.title}}</div>
          <div class="up-item"><span class="label">{{ $t('artDetail.pubTime') }}：</span>{{artDetail.createTime}}</div>
          <div class="up-item"><span class="label">{{ $t('artDetail.dataType') }}：</span>{{artDetail.cateName}}</div>
          <div class="up-item"><span class="label">{{ $t('artDetail.desc') }}：</span>
            <div class="desc">{{artDetail.description}}</div></div>
        </div>
        <div class="right">
          <el-image class="img-cover"
                    style="width: 300px;height: 225px;"
                    fit="contain"
                    :src="artDetail.coverImg" />
        </div>
      </div>
      <h3 class="m-b-30 m-t-40" style="font-size: 28px;">{{ $t('artDetail.dataDetail') }}</h3>
      <div class="file-box">
        <div class="file-item-box"
             v-for="(item, index) in artDetail.files" :key="item.md5Id + index"
             @click="toFileDetail(item)">
          <el-image class="img-cover" v-viewer
                    style="width: 250px;height: 190px;"
                    :src="item.cover"></el-image>
          <div class="file-desc ellipsis">{{item.description}}</div>
        </div>
      </div>

      <div v-if="showReviewBox">
        <h3 class="m-b-30 m-t-40" style="font-size: 28px;">专家意见</h3>
        <div class="review-box" v-for="(item, index) in artDetail.reviews" :key="index">
          <div class="first-box">
            <el-tag v-if="item.isLeader === 1" class="m-r-20">评审组长</el-tag>
            <span class="expert-name">专家{{index+1}}-{{item.expertId}}</span>
            <el-tag :class="[item.reviewResult === 1 ? 'tag-pass' : 'tag-not-pass']">
              {{item.reviewResult === 1 ? '通过' : '未通过'}}</el-tag>
          </div>
          <div class="review-content">
            {{item.content}}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="api-error">
      数据加载错误,请稍后重试
    </div>
  </div>
</template>

<script>
  import {getHomeArtDetailByArtId, getUserArtDetailByArtId, submitArt} from "@/page-home/api/home";
  import {adminArtDetail} from "@/page-admin/api/api";
  import {expertArtDetail} from "@/page-home/api/expert";

  export default {
    name: "ArtDetail",
    props: {
      detailType: {
        type: String,
        default: 'user'
        // home, user, expert, admin
      },
      backFunc: {
        type: Function
      }
    },
    data() {
      return {
        loading: false,
        btnLoading: false,
        artId: '',
        version: '',
        artDetail: {
          user: {}
        },
        showError: false
      }
    },

    computed: {
      showReviewBox() {
        if (this.detailType === 'home') return false
        if (this.detailType === 'user') {
          if (this.artDetail.status === 3) {
            return true
          } else {
            return false
          }
        }
        if (this.detailType === 'expert') {
          return true
        }
        if (this.detailType === 'admin') {
          return true
        }
      }
    },

    methods: {
      toFileDetail(item) {
        const payload = item
        this.$store.commit('SET_SEL_FILE_DETAIL', payload)
        if (this.detailType === 'admin') {
          this.$router.push('/art/file')
        } else {
          this.$router.push('/file/detail')
        }
      },

      handleBack() {
        if (this.backFunc) {
          this.backFunc()
        } else {
          this.$router.go(-1)
        }
      },

      handleBtnAudit() {
        if (this.artDetail.cateId === 0) {
          this.$message.error('您未选择分类，请补全后提交')
          return
        }
        if (this.artDetail.files.length < 1) {
          this.$message.error('您未上传文件，无法提交审核')
          return
        }
        if (this.loading) return
        this.loading = true
        const payload = {
          artId: this.artDetail.artId,
          version: this.artDetail.version
        }
        submitArt(payload).then(res => {
          this.$message.success('提交审核成功，审核完成后会收到通知')
          this.artDetail.status = 2
        }).finally(() => {
          this.loading = false
        })
      },
      handleBtnEdit() {
        this.$router.push(`/upload/edit?id=${this.artDetail.artId}&v=${this.artDetail.version}`)
      },

      judgeFileCoverImg(file) {
        const suffix = file.name.split('.')[1].toLowerCase()
        const imgList = ['png', 'jpg', 'gif', 'jpeg', 'bmp']
        const docList = ['doc', 'docx', 'excel', 'pdf', 'xlsx']
        const videoList = ['mp4', 'mpg', 'wmv']
        const zipList = ['zip', 'rar', '7z']
        const audioList = ['mp3']
        if (imgList.indexOf(suffix) !== -1) {
          return file.url
        } else if (docList.indexOf(suffix) !== -1) {
          return require('@/assets/img/doc.png')
        } else if (videoList.indexOf(suffix) !== -1) {
          return require('@/assets/img/video.png')
        } else if (zipList.indexOf(suffix) !== -1) {
          return require('@/assets/img/zip.png')
        } else if (audioList.indexOf(suffix) !== -1) {
          return require('@/assets/img/audio.png')
        } else {
          return require('@/assets/img/other.png')
        }
      }
    },

    created() {
      const params = this.$route.query
      this.artId = params.id
      this.version = params.v
      let payload
      let apiFun
      switch (this.detailType) {
        case 'user': {
          apiFun = getUserArtDetailByArtId
          payload = {
            artId: this.artId,
            version: this.version
          }
          break
        }
        case 'home': {
          apiFun = getHomeArtDetailByArtId
          payload = {
            id: this.artId
          }
          break
        }
        case 'admin': {
          apiFun = adminArtDetail
          payload = {
            artId: this.artId,
            version: this.version
          }
          break
        }
        case 'expert': {
          apiFun = expertArtDetail
          payload = {
            artId: this.artId,
            version: this.version
          }
          break
        }
        default: {
          console.error('参数错误')
        }
      }
      if (apiFun) {
        this.loading = true
        apiFun(payload).then(res => {
          this.showError = false
          this.artDetail = res.data
          if (this.detailType === 'expert') {
            // 专家视角，处理已评审
            const expertId = this.$store.state.userInfo.userId
            let found = false
            for(let review of this.artDetail.reviews) {
              if (review.expertId === expertId) {
                this.$emit('hide-submit', true)
                found = true
                break
              }
            }
            if (!found) {
              this.$emit('hide-submit', false)
            }
          }
          this.artDetail.files.forEach(item => {
            item.cover = this.judgeFileCoverImg(item)
          })
        }).catch(err => {
          this.showError = true
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .back-header {
    max-width: 1200px;
    margin: 0 auto;
    padding: 18px 16px;
    background-color: #ffffff;
  }
  .back-header-admin {
    background-color: #a0cfff;
  }
  .content-box {
    max-width: 1200px;
    height: 100%;
    margin: 40px auto;
    padding: 30px;
    border-radius: 10px;
    background-color: #FFFFFF;
    .action-box {
      margin-bottom: 30px;
    }
    .up-box {
      display: flex;
    }
  }
  .up-box {
    color: #000000;
    .left {
      .author-box {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .name {
          font-size: 30px;
          font-weight: 600;
        }
      }
      .title {
        margin-bottom: 36px;
        font-size: 30px;
        font-weight: 600;
      }
    }
    .right {
      margin-left: auto;
    }
  }
  .up-item {
    display: flex;
    margin-bottom: 16px;
    font-size: 20px;
    .label {
      width: 120px;
      color: #D6D6D9;
    }
    .desc {
      max-width: 700px;
    }
  }

  .file-box {
    .file-item-box {
      display: inline-block;
      width: 250px;
      margin-right: 30px;
      margin-bottom: 20px;
      cursor: pointer;
      overflow: hidden;
    }
    .file-desc {
      margin-top: 12px;
      font-size: 14px;
      color: #8c939d;
    }
  }

  .review-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .first-box {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .expert-name {
        margin-right: 20px;
        font-size: 28px;
        font-weight: 600;
        color: #000000;
      }
    }
    .review-content {
      font-size: 20px;
      font-weight: 400;
      color: #909092;
      line-height: 32px;
    }
  }

  .tag-pass {
    font-size: 14px;
    color: #FA012A;
    border: 1px solid #EA002A;
    border-radius: 5px;
    background: none;
  }
  .tag-not-pass {
    font-size: 14px;
    color: #004DF8;
    border: 1px solid #004DF8;
    border-radius: 5px;
    background: none;
  }

  .api-error {
    margin-top: 200px;
    text-align: center;
  }
</style>
