import axios from './axiosExpert'

export function expertLogin(payload) {
  return axios.post('/api/public/expert/login', payload)
}

export function expertInfoDetail(payload) {
  return axios.post('/api/expert/info/detail')
}

export function expertArtDetail(payload) {
  return axios.post('/api/expert/art/detail', payload)
}

export function reviewList(payload) {
  return axios.post('/api/art/list/review', payload)
}

export function submitExpertReviewResult(payload) {
  return axios.post('/api/art/review/submit', payload)
}
